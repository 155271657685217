import React from 'react';

function Footer() {
  //purpose added some white space
  return (
    <div>
      <br></br>
    </div>
  );
}

export default Footer;
